<template>
	<div class="allHeader" :class="module == '01' ? '' :'allHeader02'">
		<div v-if="module == '01'" class="fixed01">
			<div class="topHead" :style="{'background':color,'color':font}">
				<div class="commWidth">
					<div>{{ dateNow }}</div>
					<div style="display: flex;">
						<div v-for="(navLi,navindex) in navList" style="display: flex;align-items: center;">
							<span class="gap" v-if="navindex>0">｜</span>
							<el-popover
								v-if="navLi.navigateType=='1'"
								placement="bottom"
								:width="200"
								trigger="hover"
							>
								<template #reference>
									<div>
										<img class="navIcon" v-if="navLi.navigateIcon" :src="commenUrl + navLi.navigateIcon" alt=""/>
										{{navLi.navigateName}}
									</div>
								</template>
								<template #default>
									<img class="navImg" :src="commenUrl + navLi.navigateImage" alt="">
								</template>
							</el-popover>
							<div v-else @click="createAhtml(navLi.navigateUrl)">
								<div>
										<img class="navIcon" v-if="navLi.navigateIcon" :src="commenUrl + navLi.navigateIcon" alt=""/>
										{{navLi.navigateName}}
									</div>
							</div>
						</div>
						<div class="inputBox">
							<el-input @keyup.enter="onSearch" v-model="inputValue" style="width: 156px" size="small" placeholder="">
								<template #suffix>
									<el-icon class="el-input__icon">
										<component :is="Search" @click="onSearch" />
									</el-icon>
								</template>
							</el-input>
						</div>
					</div>
					
					<!-- <div @click="createAhtml('/industry')">产业工作台</div>
					<span class="gap">｜</span>
					<div @click="createAhtml('/business')">企业工作台</div>
					<span class="gap">｜</span>
					<div><el-icon class="icon" color="#8B8F9D"><Cellphone  /></el-icon>门户移动版</div> -->
				</div>
			</div>
			<div class="topNav" :style="`background-image:url(${commenUrl+backgroundImage});background-repeat: no-repeat;background-size: 100% 100%;`">
				<div class="commWidth">
					<div class="topLeftBox" style="width: 340px;">
						<!-- <img class="logo" :src="commenUrl + portalInfo.logo" alt=""> -->
						<img class="logo" v-if="portalInfo.logo" :src="commenUrl+portalInfo.logo" @load="getImg" style="width: auto;height: 78px;max-width: 100%;max-height: 100%;" :style="{'max-width':(340-titleWidth-10)+'px'}">
						<!-- <img class="logo" :src="commenUrl+portalInfo.logo" v-if="portalInfo.logoShape=='1'" style="width: 152px;height: 64px;"> -->
						<div class="titBox" :style='{width:titleWidth+"px"}'>
							<div class="bigT" :class="'wui-column-color-'+theme" :style='{width:titleWidth+"px"}'>{{ portalInfo.name }}</div>
							<div class="smallT" :style='{width:titleWidth+"px"}'>{{ portalInfo.subName }}</div>
						</div>
					</div>
					<div class="topRightBox">
						<div class="headLi" @click="toHref(headerLi)"
							:class="[selectedId == headerLi.id ? 'wui-column-color-'+theme : '',selectedId == headerLi.id ? 'wui-column-border-'+theme : '']"
							:style="{color:selectedId == headerLi.id?(columnColor+' !important'):columnFont,'borderColor':selectedId == headerLi.id?(columnColor+' !important'):'transparent'}"
							v-for="(headerLi, headerIndex) in headerList" :key="headerIndex">
							{{ headerLi.columnName }}
						</div>
						<div style="clear: both;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="fixed02"> 
			<div class="topHead topHead2" :style="{'background':color,'color':font}">
				 <div class="commWidth">
					
					<div>{{ dateNow }}</div>
					<div>
						<div style="display: inline-flex;" v-for="(navLi,navindex) in navList">
							<span class="gap" v-if="navindex>0">｜</span>
							<el-popover
								v-if="navLi.navigateType=='1'"
								placement="bottom"
								:width="200"
								trigger="hover"
							>
								<template #reference>
									<div>
										<img class="navIcon" v-if="navLi.navigateIcon" :src="commenUrl + navLi.navigateIcon" alt=""/>
										{{navLi.navigateName}}
									</div>
								</template>
								<template #default>
									<img class="navImg" :src="commenUrl + navLi.navigateImage" alt="">
								</template>
							</el-popover>
							<span v-else @click="createAhtml(navLi.navigateUrl)">
									<div>
										<img class="navIcon" v-if="navLi.navigateIcon" :src="commenUrl + navLi.navigateIcon" alt=""/>
										{{navLi.navigateName}}
									</div>
							</span>
						</div>
					</div>
 				</div>
			</div>
			<div class="topNav topNav2" :style="`background-image:url(${commenUrl+backgroundImage});background-repeat: no-repeat;background-size: 100% 100%;`">
				<div class="commWidth">
					<div class="topLeftBox">
						<!-- <img class="logo" v-if="portalInfo.logo" :src="commenUrl + portalInfo.logo" alt=""> -->
						<img class="logo" :src="commenUrl+portalInfo.logo" @load="getImg" v-if="portalInfo.logo" style="width: auto;height: 78px;max-width: 100%;max-height: 100%;" :style="{'max-width':(710-titleWidth-10)+'px'}">
						<!-- <img class="logo" :src="commenUrl+portalInfo.logo" v-if="portalInfo.logoShape=='1'" style="width: 152px;height: 64px;"> -->
						<div class="titBox" :style='{width:titleWidth+"px"}'>
							<div class="bigT" :class="'wui-column-color-'+theme" :style='{width:titleWidth+"px"}'>{{ portalInfo.name }}</div>
							<div class="smallT" :style='{width:titleWidth+"px"}'>{{ portalInfo.subName }}</div>
						</div>
					</div>
					<div class="topRightBox">
						<el-input v-model="inputValue" @keydown="onSearch" style="width: 468px;height:36px;border-style: solid;border-width: 1px;" :class="['wui-column-border-'+theme]" placeholder="请输入要搜索的内容" clearable >
							<template #append>
								<el-button @click="onSearch" :class="['wui-column-background-'+theme]">搜索</el-button>
							</template>
						</el-input>
					</div>
				</div>
			</div>
			<div class="navList" :class="'wui-column-background-'+theme">
				<div class="commWidth">
					<div class="headLi" @click="toHref(headerLi)"
						:class="[selectedId == headerLi.id ? `headActive` : '']"
						v-for="(headerLi, headerIndex) in headerList" :key="headerIndex">
						<div class="column-name">{{ headerLi.columnName }}</div>
					</div>
					<div style="clear: both;"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  onMounted,
  computed,
  inject,
  watch
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { Cellphone,Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import operate from "../../js/operate.js";
export default {
  name: "szncLogin",
  components: {
	Cellphone,
    Search,
  },
  setup() {
    const { proxy } = getCurrentInstance();
	const dateNow = proxy.date.getDay()+' '+proxy.date.getWeek()+' '+'农历 '+proxy.date.sloarToLunar().lunarMonth+'月'+proxy.date.sloarToLunar().lunarDay+' '+proxy.date.getjq()
	// const nlDate = lunarMonth
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const theme = computed(() => store.state.theme);
    const windowWidth = computed(() => store.state.windowWidth);
    const module = computed(() => store.state.module);
    const color = computed(() => store.state.color);
    const font = computed(() => store.state.font);//获取门户信息
    const portalInfo = computed(() => store.state.portalInfo);
	const backgroundImage = computed(() => store.state.backgroundImage);
	const columnColor = computed(() => store.state.columnColor);
	const columnFont = computed(() => store.state.columnFont);
	const titleWidth= ref(0)
	if(portalInfo.value.name){
		let fontLength = portalInfo.value.name.length>portalInfo.value.subName.length?portalInfo.value.name.length:portalInfo.value.subName.length
		let showLength = module.value == '01'?20:20
		let showWeight = module.value == '01'?340:710
		titleWidth.value =fontLength*showLength>showWeight?showWeight:fontLength*showLength
	}
	const logoWidth = ref('100%')
	const getImg = e=>{
		logoWidth.value = document.getElementsByClassName('logo')[0].width
	}
	watch(portalInfo,(e)=>{
		let fontLength = e.name.length>e.subName.length?e.name.length:e.subName.length
		let showLength = module.value == '01'?20:20
		let showWeight = module.value == '01'?340:710
		titleWidth.value = fontLength*showLength>showWeight?showWeight:fontLength*showLength
	})
	watch(logoWidth,(e)=>{
		let leftWeight = module.value == '01'?340:710
		titleWidth.value = leftWeight-10-e>0?leftWeight-10-e:0
		// (710-titleWidth.value-10)+'px'
	})
	// titleWidth.value = portalInfo.value.name.length*20
	const inputValue = ref('')
	const preview = ref(true)
	if(inject('preview')===undefined){
		preview.value = true
	}else{
		preview.value = inject('preview')
	}
    //获取栏目配置
    let headerList = ref([
      {
        columnName: "首页",
        columnProperty: "1",
        columnContent: '{"href":"/"}',
        target: "1",
      },
    ]);
	const navList = ref([

	])
	// 获取所有导航
	const getNavList = () => {
      proxy
        .$request({
          url: "PROTAL_N_INDUSTRYNAVIGATE_FINDALL",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.data.code == "000") {
			navList.value = res.data.result
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getList = () => {
      proxy
        .$request({
          url: "INDUSTRYCOLUMNMANAGE_FINDPAGELIST",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.data.code == "000") {
			let data = res.data.result.filter(i=>{
				return i.isShow=='0'
			})
            headerList.value = headerList.value.concat(data);
			selectedId.value = headerList.value[0].id;
            headerList.value.map((item) => {
              if (item.columnProperty == 0) {
                item.path = "/secondPage";
                item.query = { pid: item.columnContentId, columnid: item.id };
              } else if (item.columnProperty == 1) {
                item.path = "/baseMoudle";
                item.query = { id: item.columnContentId, columnid: item.id };
              }
            });
            console.log(headerList.value);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //获取门户信息
    const getPortalInfo = () => {
      proxy
        .$request({
          url: "INDUSTRYMANGE_FINDDEFAULT",
          method: "post",
        })
        .then((res) => {
          if (res.data.code == "000") {
            let result = res.data.result;
            store.commit("setTheme", result.theme);
            store.commit("setModule", result.module);
            store.commit("setColor", result.color);
            store.commit("setFont", result.font);
            store.commit("setPortalInfo",result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    let selectedId = ref("");
    let isShow = ref(true);
    const toHref = (e, ish5) => {
	  if(!preview.value)return
      selectedId.value = e.id;
      toPage({
        data: JSON.parse(e.columnContentId||e.columnContent),
        type: e.columnProperty,
        target: e.target,
		openType:e.openType
      });
      if (ish5) isShow.value = false;
    };
    // 跳转处理
    const toPage = (e) => {
	  if(!preview.value)return
      let href = "";
      let str = "";
      if (e.type == "0") {
        // 无操作
      } else if (e.type == "1") {
        //
		if(e.openType=='1'){
			href = 'iframePage';
			let obj = {
				page: e.data.href,
			};
			let params = btoa(JSON.stringify(obj))
			href = href +'?params='+params
			e.target = "1"
		}else{
			href = e.data.href
		}
      } else {
        let operateLi = operate.find((l) => e.type == l.value);
        href = operateLi.href;
        e.inner = operateLi.inner;
        e.target = operateLi.target||"1"
        if (operateLi.parameter && operateLi.parameter.length > 0) {
          operateLi.parameter.forEach((element, index) => {
            let arr = element.value.split(",");
            let name = "";
            if (arr.length > 1) {
              arr.forEach((m) => {
                if (!name) {
                  name = e.data[m];
                }
              });
            } else {
              name = e.data[element.value];
            }
            if (index == 0) {
              str += "?" + element.name + "=" + name;
            } else {
              str += "&" + element.name + "=" + name;
            }
          });
        }
      }
      openWindow(href, e.target, e.inner, str);
    };
    const openWindow = (href, target, inner, str) => {
      if (!href) return;
      if (target == "1") {
        // 当前页签打开
        router.push(href + str);
      } else {
        if (inner) {
          const url = router.resolve({
            path: href,
          });
          proxy.tool.openWindow(url.href + str);
        } else {
          proxy.tool.openWindow(href);
        }
      }
    };
    const createAhtml = (url) => {
		if(!preview.value)return
		if(url && url.substr(0,4).toLowerCase() == "http" ){
            
		}else{
		  var localObj = window.location;
		  url = localObj.protocol + "//" + localObj.host+url
		}
		proxy.tool.openWindow(url);
    };
    let currentDate = ref("");
    const getCurrentDate = () => {
      // 获取当前时间的年、月、日
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从0开始，需要加1
      const day = date.getDate();

      // 获取当前时间的星期几
      const weekDay = date.getDay(); // 返回的是0-6，0表示星期日，1表示星期一，以此类推
      if (weekDay == 0) {
        currentDate.value = `${year}年${month}月${day}日 星期日`;
      } else {
        currentDate.value = `${year}年${month}月${day}日 星期${weekDay}`;
      }
      // 打印结果
    };
    // getCurrentDate();
	const onSearch = (e)=>{
		console.log(e,'pppp')
		if(!preview.value)return
		if(!e.keyCode){
			router.push({
				path:'/searchPage',
				query:{'search':inputValue.value}
			})
		}else if(e.keyCode==13){
			router.push({
				path:'/searchPage',
				query:{'search':inputValue.value}
			})
		}
	}
    onMounted(() => {
		getNavList()
		getList();
		if(!preview.value){
			getPortalInfo()
		}
		
	});
    return {
	  inputValue,
      headerList,
      toHref,
      selectedId,
      isShow,
      portalInfo,
      theme,
      windowWidth,
      module,
      createAhtml,
      currentDate,
      Search,
	  Cellphone,
	  onSearch,
	  navList,
	  color,
	  font,
	  dateNow,
	  titleWidth,
	  getImg,
	  logoWidth,
	  backgroundImage,
	  columnFont,
	  columnColor
    };
  },
};
</script>
<style lang="less" scoped>

.commWidth{
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	justify-content: space-between;
}
.navImg{
	width: 175px;
	height: 175px
}
.navIcon{
	height: 24px;
	width: 24px;
    vertical-align: middle;
	margin-right: 4px;
}
.allHeader{
	width: 100%;
	position: relative;
	min-height: 138px;
	:deep(.el-input__wrapper) {
		box-shadow: none;
	}
	:deep(.el-input__wrapper:hover) {
		box-shadow: none;
	}
	.fixed01,.fixed02{
		width: 100%;
		position: fixed;
		z-index: 99;
		background-color: #fff;
	}
	.topHead{
		width: 100%;
		height: 40px;
		//background: #1D202A;
		color: #000;
		font-weight: 400;
		font-size: 12px;
		.commWidth{
			display: flex;
			// justify-content: right;
			height: 40px;
			line-height: 40px;
			div{
				cursor: pointer;
			}
			.gap{
				margin: 0 15px;
			}
			.icon{
				width: auto;
				vertical-align: middle;
				margin-right: 10px;
			}
			.inputBox{
				margin-left: 17px;
			}
		}
	}
	.topHead2{
		// background: #F2F2F2;;
	}
	.topNav{
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px 6px 9px -6px rgba(184, 184, 184, 0.5);
		.commWidth{
			height: 98px;
			padding: 17px 0;
			display: flex;
			justify-content: space-between;
			.topLeftBox{
				display: flex;
				width: 710px;
			 	.logo{
					// width: 64px;
					// height: 64px;
					background: #F2F2F2;
					margin-right: 15px;
				}
				.titBox{
					.bigT{
						font-family: MyBinBold;
						font-weight: bold;
						font-size: 20px;
						line-height: 20px;
						margin-bottom: 10px;
						color: #FF403E;
						padding-top: 4px;
						text-overflow: ellipsis;
						overflow: hidden;
						text-wrap: nowrap;
					}
					.smallT{
						font-family: PingFangSC, PingFang SC;
						font-size: 16px;
						color: #848484;
						line-height: 22px;
						text-overflow: ellipsis;
						overflow: hidden;
						text-wrap: nowrap;
					}
				}
			}
			.topRightBox{
				display: flex;
				align-items: center;
				.headLi {
					float: left;
					font-weight: 500;
					font-size: 16px;
					color: #333333;
					font-family: PingFangSC-Medium;
					text-align: center;
					cursor: pointer;
					padding: 10px 16px;
					border-bottom-width: 3px;
					border-bottom-style: solid;
					border-bottom-color: transparent;

				}
				.headLi.headActive {
					// color: #FF403E;
					// border-bottom: 3px solid #FF403E;
				}
			}
		}
	}
	.topNav2{
		height: 110px;
		.commWidth{
			padding: 23px 0;
			height: 110px;
			.topLeftBox{
				width: 710px;
				.logo{
					// width: 152px;
					// height: 64px;
				}
				.titBox{
					.bigT{
						padding-top: 10px;
					}
				}
			}
			.topRightBox{
				.el-input{
					// border: 2px solid #0069D2;
					border-radius: 2px;
					.el-input__wrapper{
						border-radius: 0;
					}
				}
				.el-input-group__append{
					width: 100px;
					border-left: 2px solid transparent;
					// background: #0069D2;
					border-radius: 0px;	
					color: #FFFFFF;	
					cursor: pointer;
					box-shadow: none;
					padding-left: 0;
    				padding-right: 0;
					button{
						width: 102px;
						background-color: #fff;
					}
				}
			}
		}
	}
	.navList{
		height: 46px;
		// background: #0069D2;
		.commWidth{
			height: 46px; 
			display: flex;
			.headLi {
				min-width: 120px;
				text-align: center;
				line-height: 46px;
				float: left;
				font-weight: 500;
				font-size: 16px;
				color: #FFFFFF;
				font-family: PingFangSC, PingFang SC;
				text-align: center;
				cursor: pointer;
				padding: 0px 16px;
			}
			.headLi.headActive {
				background: rgba(0, 0, 0, 0.22);
			}
		}
	}
}
.allHeader02{
	height: 196px;
}
</style>