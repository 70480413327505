<template>
	<div>
		<div class="footer" id="szncDoorFooter" v-if='windowWidth>=1200'>
			<div class="footContent">
				<!-- 相关链接 -->
				<div class="footLi">
					<div class="footTitle">
						相关链接
					</div>
					<div class="footName pointer" v-for="item in linkList" :key="item.id">
						<div @click="openLink(item.linkAddress)">{{item.linkName}}</div>
					</div>
				</div>
				<!-- 关于我们 -->
				<div class="footLi">
					<div class="footTitle">
						关于我们
					</div>
					<div class="footName pointer"
					 v-for="item in aboutList"
					 :key="item.id"
					 @click="toHref(item.id)" >
						{{item.moduleName}}
					</div>
				</div>
				<!-- 服务与支持 -->
				<div class="footLi">
					<div class="footTitle">
						服务与支持
					</div>
					<div class="footName">
						主办单位：{{portalInfo.sponsor}}
					</div>
					<div class="footName">
						联系电话：{{portalInfo.tel}}
					</div>
					<div class="footName">
						联系邮箱：{{portalInfo.email}}
					</div>
					<div class="footName">
						联系地址：{{portalInfo.address}}
					</div>
				</div>
				<!-- 服务方式 -->
				<div class="footLi" v-if="portalInfo.gzh||portalInfo.tradeImg">
					<div class="footTitle">
						服务方式
					</div>
					<div class="footName serviceWay">
						<div class="wayli" v-if="portalInfo.gzh">
							<div class="img">
								<img :src="commenUrl+portalInfo.gzh" alt="">
							</div>
							<div class="txt">官方公众号</div>
						</div>
						<div class="wayli" v-if="portalInfo.tradeImg">
							<div class="img">
								<img :src="commenUrl+portalInfo.tradeImg" alt="">
							</div>
							<div class="txt">官方正品交易通道</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footContent1">
				<div v-for="(qualificationsLi,qualificationsIndex) in qualificationsList" class="footClass">
					<img v-if="qualificationsLi.imgUrl" :src="commenUrl+qualificationsLi.imgUrl" >
					<a :href="qualificationsLi.url" v-if='qualificationsLi.certificationType=="0"' target="_blank" style="margin-right:20px;">{{qualificationsLi.name}}</a>
					<a @click="openPdf(qualificationsLi.fileUrl)" v-if='qualificationsLi.certificationType=="1"' target="_blank" style="margin-right:20px;">{{qualificationsLi.name}}</a>
				</div>
				
			</div>
			
		</div>
		<div class="footH5" v-else>
			<div class="footTit">服务与支持</div>
			<div>主办单位：{{portalInfo.sponsor}}</div>
			<div>联系电话：{{portalInfo.tel}}</div>
			<div>联系邮箱：{{portalInfo.email}}</div>
			<div>联系地址：{{portalInfo.address}}</div>
			<div>{{portalInfo.filingsNumber}}</div>
		</div>
	</div>
</template>


<script>
import { getCurrentInstance,ref,computed} from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
export default {
  name: "footer",
  setup(props) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store=useStore();
    const theme=computed(()=>store.state.theme);
    const windowWidth=computed(()=>store.state.windowWidth);
    const module=computed(()=>store.state.module);
    //获取链接列表
    let linkList=ref([])
		const	getLink=()=>{
      proxy.$request({
        url:"INDUSTRYFRIENDLYLINKMANAGE_FINDLIST",
        method: "post",
        data:{
          "isShow": "0",
        },
      }).then((res)=>{
        if(res.data.code=="000"){
          linkList.value=res.data.result;
        }
      }).catch((err)=>{
        console.log(err)
      });
    }
    getLink();
			//获取关于我们列表
    let aboutList=ref([]);
	const	getAboutus=()=>{
      proxy.$request({
        url:"INDUSTRYMODULEMAGE_FINDPAGELIST",
        method: "post",
        data:{
          "moduleCategory": "1",
        },
      }).then((res)=>{
        if(res.data.code=="000"){
          aboutList.value=res.data.result.list;
        }
      }).catch((err)=>{
        console.log(err)
      });
    }
    getAboutus();

	// 获取商务资质
	const qualificationsList = ref([])
	const getQualifications=()=>{
      proxy.$request({
        url:"PROTAL_N_INDUSTRYCERTIFICATION_FINDALL",
        method: "post",
        data:{
			showType:'0'
        },
      }).then((res)=>{
        if(res.data.code=="000"){
			qualificationsList.value=res.data.result;
        }
      }).catch((err)=>{
        console.log(err)
      });
    }
    getQualifications();
    let portalInfo=computed(() => store.state.portalInfo);
    const toHref=(id)=>{
      router.push({path:'/aboutUs',query:{"id":id}})
		}
    const openLink=(url)=>{
      window.open(url,'_blank');
    }
	const openPdf = (href)=>{
		if(!href)return
		const url = router.resolve({
			path: 'Pdfcomp',
		});
		let obj = {
			page: proxy.commenUrl + href,
		};
		let params = btoa(JSON.stringify(obj))
		url.href += '?params='+params
		proxy.tool.openWindow(url.href);
	}
    return {
      toHref,
      openLink,
      linkList,
      aboutList,
      portalInfo,
      theme,
      windowWidth,
      module,
	  qualificationsList,
	  openPdf
    };
  },
};
</script>
<style lang="less">
	.footer{
		width: 100%;
		min-height: 206px;
		padding-top: 37px;
    padding-bottom: 20px;
		background-color: #050f0b;
		.footContent {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			a{
				color: #999999;
				font-size: 14px;
				text-decoration: none;
			}
			.footLi{
				line-height: 24px;
				.footTitle{
					font-family: PingFangSC-Semibold;
					font-size: 15px;
					margin-bottom: 12px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #ffffff;
				}
				.footName{
					font-family: PingFangSC-Regular;
					font-size: 14px;
					font-weight: normal;
					letter-spacing: 0px;
					color: #999999;
				}
			}
			.pointer{
				cursor: pointer;
			}
			.serviceWay{
				display: flex;
				.wayli{
					margin-right: 14px;
					width: 88px;
					text-align: center;
					.img{
						width: 88px;
						height: 88px;
						margin-bottom: 8px;
						background-color: #ffffff;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.txt{
						font-size: 11px;
					}
				}
			}
		}
		.footContent1{
			
			text-align: center;
			display: flex;
			width: 1200px;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 auto;
			padding: 0 0 6px;
			.footClass{
				display: flex;
				cursor: pointer;
				img{
					width: 16px;
					height: 16px;
					margin-right:4px;
				}
			}
			a{
				color: #999999;
				font-size: 14px;
				text-decoration: none;
			}
		}
	}
	.footH5{
		padding: 30upx 0;
		background-color: #1a1a1c;
		text-align: center;
		font-size:24upx;
		line-height: 36upx;
		color: #999999;
		.footTit{
			margin-bottom: 22upx;
			line-height: 40upx;
			font-size: 28upx;
			color: #ffffff;
		}
	}
</style>