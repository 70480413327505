let Api = {
	"FINDPAGEINDUSTRYARTICLECLASSMAGETREE": "/portal-api/n/industryArticleClassMage/findIndustryArticleClassMageTree", //分页查询文章分类树
	"INDUSTRYARTICLEMAGE_FINDPAGELIST": "/portal-api/n/industryArticleMage/findPageList", //产业文章管理分页查询列表
	"INDUSTRYARTICLEMAGE_FINDINFO": "/portal-api/n/industryArticleMage/findInfo", //产业文章管理文章详情
	"INDUSTRYMODULEMAGE_FINDPAGELIST": "/portal-api/n/IndustryModuleMage/findPageList", //内容管理和关于我们模块分页查询列表
	"INDUSTRYCOLUMNMANAGE_FINDPAGELIST": "/portal-api/n/IndustryColumnManage/findList", //内容管理和关于我们模块分页查询列表
	"INDUSTRYMODULEMAGE_FINDINFO": "/portal-api/n/IndustryModuleMage/findInfo", //内容管理和关于我们模块分页查询列表
	"INDUSTRYLMODULECONTENT_FINDLIST": "/portal-api/n/IndustrylModuleContent/findList", //内容管理和关于我们模块 内容查询列表
	"INDUSTRYMANGE_FINDDEFAULT": "/portal-api/n/IndustryMange/findDefault", //查询默认产业基本信息
	"INDUSTRYFRIENDLYLINKMANAGE_FINDLIST": "/portal-api/n/IndustryFriendlyLinkManage/findList", //友情链接查询列表
	"GET_CODEBASE64": "/portal-api/n/codecBase64", //门户二维码获取
	"PROTAL_FINDDEFAULTINFO": "/portal-api/n/finishProtal/findDefaultInfo", //B2B装修复制
	"PROTAL_N_SHPROTAL_BYID": "/portal-api/n/finishProtal/findById", //查询专题
	
	"PROTAL_N_INDUSTRYNAVIGATE_FINDALL": "/portal-api/n/industryNavigate/findAll", //查询所有显示的导航

	"PORTAL_W_INDUSTRYARTICLEMAGE_FINDPAGELIST": "/portal-api/n/industryArticleMage/findPageList", //分页查询列表
	"BTB_W_INDUSTRYARTICLEMAGE_FINDPAGELIST": "/b2b/n/industryArticleMage/findPageList", //分页查询列表
	"PORTAL_N_VARIETY_PAGE": "/portal-api/n/variety/page",//品种名录
	"PROTAL_N_INDUSTRYCERTIFICATION_FINDALL": "/portal-api/n/IndustryCertification/findList", //查询所有商务资质
	
	"PORTAL_N_ARTICLEMAGE_FINDMAP":"/portal-api/n/industryArticleMage/findMap", // 主轮播样式三-资讯
	"BTB_W_ARTICLEMAGE_FINDMAP":"/b2b/n/industryArticleMage/findMap",

}
module.exports = Api